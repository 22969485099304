import { SET_USER, LOGIN, LOGOUT } from "../constants/user";
import { defaultHandler } from "../requests/global";
import { wait, unexpectedError, notify } from "./index";
import * as validator from "../validators/user";

export function setUser(payload) {
  return { type: SET_USER, payload };
}

export function login(payload) {
  return { type: LOGIN, payload };
}

export function logout(payload) {
  return { type: LOGOUT, payload };
}

export function loginUser(body, history) {
  return async (dispatch) => {
    const url = "/poster/login";
    dispatch(wait(true));
    if (body.email != null) {
      const [res, status] = await defaultHandler(url, body);
      if (status == "ok") {
        if (res.status == "success") {
          if (res.response.poster) {
            dispatch(setUser(res.response.poster));
            dispatch(login());
            if (res.response.poster.setup == "incomplete") {
              history.push("/account/setpassword");
            } else {
              history.push("/dashboard");
            }
          } else {
            dispatch(
              notify({
                message: "Incorrect username or password",
                status: "error",
              })
            );
          }
        } else {
          dispatch(
            notify({
              message: "Incorrect username or password",
              status: "error",
            })
          );
        }
      } else {
        dispatch(unexpectedError());
      }
    } else {
      dispatch(
        notify({ message: "please provide an email address", status: "error" })
      );
    }
    dispatch(wait(false));
  };
}

export function resetPassword(body, history) {
  return async (dispatch) => {
    const url = "/poster/password/update";
    dispatch(wait(true));
    var [status, message] = validator.password(body);
    if (status == "success") {
      var [res, status] = await defaultHandler(url, body);
      if (status === "ok") {
        if (res) {
          localStorage.password = body.password;
          dispatch(setUser(res.response.poster));
          dispatch(
            notify({
              message: "Password updated successfully",
              status: "success",
            })
          );
          history.push("/");
          dispatch(wait(false));
        } else {
          dispatch(unexpectedError());
        }
      } else {
        dispatch(unexpectedError());
      }
    } else {
      dispatch(notify({ message: message, status: status }));
    }
    dispatch(wait(false));
  };
}

export function updatePassword(body, history) {
  return async (dispatch) => {
    const url = "/poster/credential/update";
    dispatch(wait(true));
    var [status, message] = validator.profile(body);
    console.log(body);
    if (status == "success") {
      var [res, status] = await defaultHandler(url, body);
      if (status === "ok") {
        if (res) {
          dispatch(setUser(res.response.vendor[0]));
          dispatch(
            notify({
              message: "Profile updated successfully",
              status: "success",
            })
          );
          history.push("/welcome");
        } else {
          dispatch(unexpectedError());
        }
      } else {
        dispatch(unexpectedError());
      }
    } else {
      dispatch(notify({ message: message, status: status }));
    }
    dispatch(wait(false));
  };
}

export function updateProfile(body, history) {
  return async (dispatch) => {
    const url = "/poster/profile/update";
    dispatch(wait(true));
    var [status, message] = validator.profile(body);
    console.log(body);
    if (status == "success") {
      var [res, status] = await defaultHandler(url, body);
      if (status === "ok") {
        if (res) {
          dispatch(setUser(res.response.vendor[0]));
          dispatch(
            notify({
              message: "Profile updated successfully",
              status: "success",
            })
          );
        } else {
          dispatch(unexpectedError());
        }
      } else {
        dispatch(unexpectedError());
      }
    } else {
      dispatch(notify({ message: message, status: status }));
    }
    dispatch(wait(false));
  };
}
