import React from "react";
import { useMediaQuery } from "react-responsive";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  BrowserRouter,
} from "react-router-dom";

import Login from "./mobile/account/Login";
import Register from "./mobile/account/Register";
import Auth from "./mobile/account/Auth";
import SetPassword from "./mobile/account/SetPassword";
import Dashboard from "./mobile/dashboard/Main0";
import Postings from "./mobile/postings/Main";

const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  return isMobile ? children : null;
};

export default function Main() {
  return (
    <Mobile>
      <Switch>
        <Route exact path="/" component={Login} />
        <Route exact path="/account/login" component={Login} />
        <Route exact path="/account/register" component={Register} />
        <Route exact path="/account/auth" component={Auth} />
        <Route exact path="/account/setpassword" component={SetPassword} />
        <Route exact path="/dashboard" component={Dashboard} />
        <Route exact path="/postings" component={Postings} />
      </Switch>
    </Mobile>
  );
}
