import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { styled, useTheme } from "@mui/material/styles";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import WorkIcon from "@mui/icons-material/Work";
import GroupIcon from "@mui/icons-material/Group";
import SchoolIcon from "@mui/icons-material/School";
import Avatar from "@mui/material/Avatar";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AutoGraphIcon from "@mui/icons-material/AutoGraphRounded";
import RestaurantMenuIcon from "@mui/icons-material/RestaurantMenuRounded";
import MedicalInformationIcon from "@mui/icons-material/MedicalInformation";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCartRounded";
import PersonIcon from "@mui/icons-material/PersonRounded";
import PaymentIcon from "@mui/icons-material/PaymentRounded";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import ReviewsIcon from "@mui/icons-material/ReviewsRounded";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import LocalHospitalIcon from "@mui/icons-material/LocalHospital";
import MasksIcon from "@mui/icons-material/Masks";
import FolderIcon from "@mui/icons-material/Folder";
import HomeIcon from "@mui/icons-material/Home";
import NotificationsIcon from "@mui/icons-material/NotificationsActiveRounded";
import EventIcon from "@mui/icons-material/Event";

import UserAvatar from "../../components/profile/UploadAvatar";
import { loggedIn } from "./../../actions/user";
const drawerWidth = 300;

const urls = [
  {
    title: "Dashboard",
    url: "/dashboard",
    disabled: false,
    icon: <AutoGraphIcon style={{ color: "#fff" }} />,
  },

  {
    title: "Postings",
    url: "/postings",
    disabled: false,
    icon: <WorkIcon style={{ color: "#fff" }} />,
  },

  {
    title: "Notifications",
    url: "/notifications",
    disabled: true,
    icon: <NotificationsIcon style={{ color: "#fff" }} />,
  },
];

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

export default function Main(props) {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const theme = useTheme();
  const history = useHistory();
  //const user = useSelector((state) => state.user.user);
  const user = useSelector((state) => state.user.user);
  const [open, setOpen] = useState(props.open);
  const menu = Boolean(anchorEl);

  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  const handleDrawerClose = () => {
    setOpen(false);
    props.handleDrawer(false);
  };

  function handleClick(item) {
    history.push(item.url);
  }

  function handleMenuClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleMenuClose() {
    setAnchorEl(null);
  }

  function handleLogout() {
    //dispatch(loggedIn(false));
    localStorage.clear();
    history.push("/");
  }

  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: drawerWidth,
          justifyContent: "space-between",
          backgroundColor: "#0093e7",
          boxSizing: "border-box",
        },
      }}
      variant="persistent"
      anchor="left"
      open={open}
    >
      <div>
        <DrawerHeader>
          <div style={{ marginTop: 20 }} />
          <Grid container direction="row" style={{ padding: 10 }}>
            <Grid item>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <img style={{ width: 150 }} />
              </div>
            </Grid>
          </Grid>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon style={{ color: "#fff" }} />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {urls.map((item, index) => (
            <ListItem disabled={item.disabled} key={item.title} disablePadding>
              <ListItemButton onClick={() => handleClick(item)}>
                <ListItemIcon color="primary">{item.icon}</ListItemIcon>
                <Typography
                  variant="body1"
                  style={{ color: "#fff", fontFamily: "OpenSauceRegular" }}
                >
                  <b>{item.title}</b>
                </Typography>
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </div>

      <ListItem
        style={{
          justifySelf: "flex-end",
          justifyContent: "flex-end",
          justifyItems: "flex-end",
        }}
      >
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={1}
        >
          <Grid item md={10}>
            <Grid container direction="row" spacing={1} alignItems="center">
              <Grid item md={3}>
                <UserAvatar uri={user.avatar} />
              </Grid>
              <Grid item md={9}>
                <Typography
                  variant="body1"
                  fontFamily="OpenSauceBold"
                  noWrap
                  style={{ color: "#fff" }}
                >
                  {user.name || "Admin"}
                </Typography>
                <Typography variant="body2" style={{ color: "#fff" }} noWrap>
                  {user.email || "admin@jobs.ca"}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item md={2}>
            <IconButton onClick={handleMenuClick}>
              <MoreVertIcon style={{ color: "#fff" }} />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={menu}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              onClose={handleMenuClose}
            >
              <MenuItem disabled onClick={() => history.push("/profile")}>
                <Typography variant="body2">Profile</Typography>
              </MenuItem>
              <MenuItem onClick={handleLogout}>
                <Typography variant="body2">Logout</Typography>
              </MenuItem>
            </Menu>
          </Grid>
        </Grid>
      </ListItem>
    </Drawer>
  );
}
