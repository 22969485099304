import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";

//import Diagnosis from "../../components/web/dashboard/Diagnosis";
//import Summary from "../../components/web/dashboard/Summary";
//import Appointments from "../../components/web/dashboard/Appointments";

import * as utils from "../../functions/utils";

export default function Main() {
  //const user = useSelector((state) => state.user.user);
  return (
    <div>
      <Grid
        container
        direction="row"
        spacing={2}
        style={{ backgroundColor: "#fff" }}
      >
        <Grid item md={8}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              height: "100vh",
            }}
          >
            <div style={{ height: 50 }}></div>
            <div style={{ padding: 10 }}>
              <Grid container direction="row" justifyContent="space-between">
                <Grid item>
                  <Typography variant="h4">
                    <b></b>
                  </Typography>
                  <Typography variant="body1" color="GrayText"></Typography>
                </Grid>
                <Grid item></Grid>
              </Grid>

              <div style={{ marginTop: 20 }} />

              <div style={{ marginTop: 20 }} />
              {/* <Diagnosis />*/}
              <div style={{ marginTop: 20 }} />
              {/* <Appointments />*/}
            </div>
          </div>
        </Grid>
        <Grid item md={4}>
          {/* <Summary />*/}
        </Grid>
      </Grid>
    </div>
  );
}
