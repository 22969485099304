import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import "react-alice-carousel/lib/alice-carousel.css";
import { page } from "../../styles/main";
import { loginUser } from "../../actions/user";

import { resetPassword } from "../../actions/user";
const classes = {
  root: {
    marginTop: 10,
  },
};

const styles = {
  media: {
    height: "100%",
    paddingTop: "75%", // 16:9,
    marginTop: "30",
  },
};

export default function Main() {
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);
  const [form, setForm] = useState({ email: "", password: "" });
  const [password, setPassword] = useState("");

  function handleClick() {
    const body = { ...form, id: user.id };
    dispatch(resetPassword(body, history));
  }

  function handleChange(event) {
    const { name, value } = event.target;
    setForm((form) => ({ ...form, [name]: value }));
  }

  return (
    <div style={page.root}>
      <Grid
        style={{ height: "100vh" }}
        justifyContent="center"
        alignItems="center"
        container
        direction="row"
      >
        <Grid item xs={12} md={3} style={{ marginTop: 0, padding: 10 }}>
          <div
            style={{
              justifyContent: "center",
              alignSelf: "center",
              display: "flex",
            }}
          >
            <img
              style={{ width: 200 }}
              src={require("../../assets/logo/icon.png")}
            />
          </div>

          <div style={{ marginTop: 100 }}>
            <Typography
              variant="h6"
              fontFamily={"OpenSauceBold"}
              color="primary"
            >
              Choose your password
            </Typography>
            <div style={{ marginTop: 10 }}>
              <TextField
                onChange={handleChange}
                name="email"
                style={{ width: "100%" }}
                size="small"
                defaultValue={user.email}
                disabled
                id="email"
                label="Email"
                variant="filled"
              />
            </div>

            <div style={{ marginTop: 10 }}>
              <TextField
                onChange={handleChange}
                type="password"
                name="password"
                style={{ width: "100%" }}
                size="small"
                id="password"
                label="Password"
                variant="filled"
              />
            </div>

            <div style={{ marginTop: 10 }}>
              <TextField
                onChange={handleChange}
                type="password"
                name="rpassword"
                style={{ width: "100%" }}
                size="small"
                id="rpassword"
                label="Repeat Password"
                variant="filled"
              />
            </div>

            <div style={{ marginTop: 20 }}>
              <Button
                onClick={handleClick}
                style={{ width: "100%" }}
                variant="contained"
              >
                {" "}
                <Typography variant="body1" fontFamily="NeueBold">
                  {" "}
                  <b>Continue </b>{" "}
                </Typography>{" "}
              </Button>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
