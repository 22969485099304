import * as React from "react";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import Navigation from "../../components/mobile/page/Navigation";
import Default from "./Default";

import { getPostings } from "../../actions/postings";

import * as utils from "../../functions/utils";
import AddModal from "../../components/mobile/postings/AddPosting";
//import NewDiagnosis from "../../components/mobile/diagnostics/NewDiagnosis";
//import Collections from "../../components/mobile/dashboard/Collections";
//import DemoChart from "../../components/mobile/dashboard/DemoChart";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";

//import { getGallery } from "../../actions/gallery";
//import { patientDiagnosis } from "../../actions/diagnose";
//import { loggedIn } from "../../actions/user";

export default function Main() {
  const user = useSelector((state) => state.user.user);
  const dispatch = useDispatch();
  const history = useHistory();
  const [modal, setModal] = useState(false);

  useEffect(() => {
    //dispatch(getGallery({ user: user._id }));
    const body = { id: user.id };
    dispatch(getPostings(body));
  }, []);

  useEffect(() => {
    //dispatch(patientDiagnosis({ id: user.id }));
  }, []);

  function handleClick() {
    setModal(true);
  }

  return (
    <React.Fragment>
      <div style={{ marginTop: 20 }} />
      <AddModal open={modal} close={() => setModal(false)} />
      <div sx={{ p: 2, pb: 0 }}>
        <Grid
          container
          direction="row"
          justifyContent="center"
          sx={{ p: 2, pb: 0 }}
        >
          <Grid item xs={10}>
            <Typography variant="h5" color="primary">
              {" "}
              <b>Postings</b>
            </Typography>
            <Typography variant="body1" color="GrayText">
              Create and manage postings
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <IconButton onClick={handleClick} color="primary">
              <AddIcon style={{ fontSize: 40 }} size="large" />
            </IconButton>
          </Grid>
        </Grid>
      </div>

      <Grid
        container
        direction="row"
        justifyContent="center"
        sx={{ p: 2, pb: 0 }}
      >
        <Grid item xs={12}>
          <Default />
        </Grid>
      </Grid>

      <Navigation />
    </React.Fragment>
  );
}
