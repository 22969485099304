export const AUTH = "AUTH";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const UNEXPECTED_ERROR = "UNEXPECTED_ERROR";
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const PROFILE_UPDATE = "PROFILE_UPDATE";
export const SLOT = "SLOT";
export const LOGGED_IN = "LOGGED_IN";
export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const SET_USER = "SET_USER";

