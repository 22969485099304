import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Pagination from "@mui/material/Pagination";
import AddIcon from "@mui/icons-material/Add";
import GridItem from "../../components/mobile/postings/PostingItem";

import { blue, red } from "@mui/material/colors";
import {
  getPostings,
  fetchPosting,
  deletePosting,
} from "../../actions/postings";

import AddModal from "../../components/mobile/postings/AddPosting";
import EditModal from "../../components/mobile/postings/EditPosting";
import WizardModal from "../../components/mobile/postings/Wizard";
import ConfirmModal from "../../components/mobile/modals/Confirmation";

export default function Main(props) {
  const history = useHistory();
  const [display, setDisplay] = useState(0);
  const [tab, setTab] = useState(0);
  const [modal, setModal] = useState(false);
  const [edit, setEdit] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [wizard, setWizard] = useState(false);

  const postings = useSelector((state) => state.postings.postings);
  const posting = useSelector((state) => state.postings.posting);
  const [data, setData] = useState(postings ? postings : []);
  const dispatch = useDispatch();

  const [pagination, setPagination] = useState({
    start: 0,
    end: 8,
    page: 1,
    pages: Math.ceil(data.length / 9),
  });

  useEffect(() => {
    setData(postings ? postings : []);
  }, [postings]);

  useEffect(() => {
    setData(postings ? postings : []);
  }, []);

  function handlePageChange(event, value) {
    setPagination((pagination) => ({
      ...pagination,
      start: (value - 1) * 9,
      end: value * 9 - 1,
      page: value,
    }));
  }

  function handleFilter(searchText, province) {
    if (province == "all") {
      setData(
        postings
          .filter((item) => item.province !== province)
          .filter((item) => item.title.includes(searchText))
      );
    } else {
      setData(
        postings
          .filter((item) => item.province === province)
          .filter((item) => item.title.includes(searchText))
      );
    }
  }

  function handleEdit(item) {
    setEdit(true);
    dispatch(fetchPosting(item));
  }

  function handleClick() {
    setModal(true);
  }

  function handleDelete() {
    dispatch(deletePosting(posting));
  }

  return (
    <div>
      <AddModal open={modal} close={() => setModal(false)} />
      <EditModal open={edit} close={() => setEdit(false)} />
      <WizardModal open={wizard} close={() => setWizard(false)} />
      <ConfirmModal
        open={confirm}
        close={() => setConfirm(false)}
        confirm={() => handleDelete()}
      />

      <div style={{ marginTop: 30 }}>
        <Grid container direction="row">
          <Grid item xs={12}>
            <Toolbar
              update={(search, location) => handleFilter(search, location)}
            />
            <div style={{ marginTop: 20 }} />

            <Grid container direction="row" spacing={1}>
              {data.map(
                (item, index) =>
                  pagination.start <= index &&
                  pagination.end >= index && (
                    <Grid item xs={12} lg={4} md={4}>
                      <GridItem
                        data={item}
                        edit={() => handleEdit(item)}
                        delete={() => setConfirm(true)}
                      />
                    </Grid>
                  )
              )}
            </Grid>

            <div style={{ marginTop: 20 }} />
            {data.length > 8 && (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Pagination
                  count={pagination.pages}
                  page={pagination.page}
                  onChange={handlePageChange}
                  variant="outlined"
                  shape="rounded"
                />
              </div>
            )}
            <div style={{ marginTop: 150 }} />
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

function Toolbar(props) {
  const [form, setForm] = useState({ search: "", location: "all" });

  function handleChange(event) {
    const { name, value } = event.target;
    if (name == "search") {
      props.update(value, form.location);
    } else {
      props.update(form.search, value);
    }
    setForm((form) => ({ ...form, [name]: value }));
  }

  return (
    <Grid container direction="row" spacing={1}>
      <Grid item xs={8}>
        <TextField
          required
          fullWidth
          id="search"
          name="search"
          label="Search"
          defaultValue=""
          onChange={handleChange}
          variant="filled"
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          required
          fullWidth
          id="province"
          name="province"
          label="Province"
          select
          onChange={handleChange}
          defaultValue="all"
          variant="filled"
        >
          <MenuItem value={"all"}> All </MenuItem>
          <MenuItem value={"AB"}> Alberta </MenuItem>
          <MenuItem value={"BC"}> British Columbia </MenuItem>
          <MenuItem value={"MB"}> Manitoba </MenuItem>
          <MenuItem value={"NB"}> New Brunswick </MenuItem>
          <MenuItem value={"NL"}> Newfoundland and Labrador </MenuItem>
          <MenuItem value={"NT"}> Northwest Territories </MenuItem>
          <MenuItem value={"NS"}> Nova Scotia </MenuItem>
          <MenuItem value={"NU"}> Nuvanut </MenuItem>
          <MenuItem value={"ON"}> Ontario </MenuItem>
          <MenuItem value={"PE"}> Prince Edward Island </MenuItem>
          <MenuItem value={"QC"}> Quebec </MenuItem>
          <MenuItem value={"SK"}> Saskatechewan </MenuItem>
          <MenuItem value={"YT"}> Yukon </MenuItem>
        </TextField>
      </Grid>
    </Grid>
  );
}
