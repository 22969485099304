import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Card from '@mui/material/Card';
import Divider from '@mui/material/Divider';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Avatar from '@mui/material/Avatar';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';


import { page } from '../../styles/main';
import { loginUser } from '../../actions/user';


const classes = {
  root: {
    marginTop: 10
  }
}

const styles = {
  media: {
    height: '100%',
    paddingTop: '75%', // 16:9,
    marginTop: '30'
  },
};






export default function Main() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [form, setForm] = useState();



  function handleClick() {
    dispatch(loginUser(form, history));
  }

  function handleChange(event) {
    const { name, value } = event.target;
    setForm(form => ({ ...form, [name]: value }));
  }

  function handleKeyPress(event) {
    if (event.key === 'Enter') {
      handleClick();
    }
  }




  return (
    <div style={page.root}>
      <Grid style={{ height: "100vh" }} justifyContent="center" alignItems="center" container direction="row">
        <Grid item xs={12} md={3} style={{ marginTop: 0, padding: 10, }}>


          <div style={{ justifyContent: "center", alignSelf: "center", display: "flex" }}>

          </div>

          <div style={{ marginTop: 0 }}>
            <Typography variant="h6" color="primary"><b>Authentication </b></Typography>
            <div style={{ marginTop: 10 }}>
              <TextField onChange={handleChange}
                name="code" style={{ width: "100%" }}
                size="small"
                id="credential"
                label="Code"
                variant="filled" />
            </div>


            <div style={{ marginTop: 10 }}>
              <Button onClick={handleClick} style={{ width: "100%" }} variant="contained">
                <Typography variant="body1" style={{ fontFamily: "SpaceBold" }}> <b>Continue </b> </Typography>
              </Button>
            </div>
          </div>

        </Grid>
      </Grid>

    </div>
  )
}