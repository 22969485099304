import { defaultHandler } from "../requests/global.js";
import { unexpectedError, wait, notify } from "./index";
import {
  FETCH_INSTITUTIONS,
  FETCH_INSTITUTION,
} from "../constants/institutions";

export function addInstitution(body, props) {
  const url = "/institution/institutions/add";
  return async (dispatch) => {
    dispatch(wait(true));
    var [res, status] = await defaultHandler(url, body);
    if (status == "ok") {
      if (res.response != null) {
        dispatch(fetchInstitutions(res.response.institutions));
        dispatch(
          notify({ message: "Record created successfully", status: "success" })
        );
        props.close();
      } else {
        dispatch(unexpectedError());
      }
    } else {
      dispatch(unexpectedError());
    }
    dispatch(wait(false));
  };
}

export function getInstitutions() {
  const url = "/institution/institutions/all";
  return async (dispatch) => {
    dispatch(wait(true));
    const [res, status] = await defaultHandler(url, {});
    if (status == "ok") {
      if (res.response != null) {
        dispatch(fetchInstitutions(res.response.institutions));
      } else {
      }
    } else {
      dispatch(unexpectedError());
    }
    dispatch(wait(false));
  };
}

export function updateInstitution(body, props) {
  const url = "/institution/institutions/update";
  return async (dispatch) => {
    dispatch(wait(true));
    var [res, status] = await defaultHandler(url, body);
    if (status === "ok") {
      if (res.response != null) {
        dispatch(fetchInstitutions(res.response.institutions));
        dispatch(
          notify({
            message: "Record updated successfully",
            status: "success",
          })
        );
        props.close();
      } else {
      }
    } else {
      dispatch(unexpectedError());
    }

    dispatch(wait(false));
  };
}

export function deleteInstitution(body, history) {
  const url = "/institution/institutions/remove";
  return async (dispatch) => {
    dispatch(wait(true));
    var [res, status] = await defaultHandler(url, body);
    if (res.response != null) {
      dispatch(fetchInstitutions(res.response.institutions));
      dispatch(
        notify({
          message: "Listing updated successfully",
          status: "success",
        })
      );
    } else {
      dispatch(
        notify({
          message: "An error occurred",
          status: status,
        })
      );
    }
    dispatch(wait(false));
  };
}

export function fetchInstitutions(payload) {
  return { type: FETCH_INSTITUTIONS, payload };
}

export function fetchInstitution(payload) {
  return { type: FETCH_INSTITUTION, payload };
}
