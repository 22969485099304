import { FETCH_POSTING, FETCH_POSTINGS } from "../constants/postings";

const initialState = {
  posting: {},
  postings: [],
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_POSTING:
      return Object.assign({}, state, {
        posting: action.payload,
      });
    case FETCH_POSTINGS:
      return Object.assign({}, state, {
        postings: action.payload,
      });
  }
  return state;
}

export default reducer;
