import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import NewPosting from "./NewPosting";

export default function Main(props) {
  const [open, setOpen] = useState(props.open);
  const dispatch = useDispatch();

  function handleClose() {
    props.close();
  }

  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth={"lg"}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <AppBar elevation="0" color="inherit" sx={{ position: "relative" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>

          <Typography
            sx={{ ml: 2, flex: 1 }}
            fontFamily={"SpaceBold"}
            variant="h6"
            component="div"
          >
            New Posting
          </Typography>
        </Toolbar>
      </AppBar>

      <DialogContent style={{ justifyContent: "center" }}>
        <NewPosting handleClose={() => props.close()} />
      </DialogContent>
    </Dialog>
  );
}
