import * as React from "react";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { useDispatch, useSelector } from "react-redux";

import CssBaseline from "@mui/material/CssBaseline";

import Header from "../../components/page/Header";
import Sidebar from "../../components/page/Sidebar";
import Default from "./Default";

import { getPostings } from "../../actions/postings";

const drawerWidth = 240;

const Content = styled("main", {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(2),
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

export default function Main() {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(true);
  const [screen, setScreen] = useState("default");
  const user = useSelector((state) => state.user.user);

  let { page } = useParams();

  useEffect(() => {
    setScreen(page == null ? "default" : page);
  }, [page]);

  useEffect(() => {
    const body = { id: user.id };
    dispatch(getPostings(body));
  }, []);

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Header title="" open={open} handleDrawer={(value) => setOpen(value)} />
      <Sidebar open={open} handleDrawer={(value) => setOpen(value)} />

      <Content open={open}>
        <DrawerHeader />
        {getScreen(screen, open)}
      </Content>
    </Box>
  );
}

function getScreen(screen, open) {
  switch (screen) {
    case "default":
      return <Default open={open} />;
    default:
      return <Default open={open} />;
  }
}
