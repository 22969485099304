import * as React from "react";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { useDispatch, useSelector } from "react-redux";

import CssBaseline from "@mui/material/CssBaseline";

import Header from "../../components/page/Header";
import Sidebar from "../../components/page/Sidebar";
import Dashboard from "./Dashboard";
import { getInstitutions } from "../../actions/institutions";

const drawerWidth = 240;

const Content = styled("main", {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(0),
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  //marginLeft: `-${drawerWidth}px`,
  marginLeft: -300,
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

export default function Main() {
  const dispatch = useDispatch();
  const theme = useTheme();
  // const user = useSelector((state) => state.user.user);
  const [open, setOpen] = React.useState(true);
  const [screen, setScreen] = useState("summary");

  let { page } = useParams();

  useEffect(() => {
    dispatch(getInstitutions());
  }, []);
  useEffect(() => {
    setScreen(page == null ? "summary" : page);
  }, [page]);

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <div style={{ display: "flex" }}>
      <Sidebar open={open} handleDrawer={(value) => setOpen(value)} />

      <Content open={open}>{getScreen(screen)}</Content>
    </div>
  );
}

function getScreen(screen) {
  switch (screen) {
    case "summary":
      return <Dashboard />;
    default:
      return <Dashboard />;
  }
}
