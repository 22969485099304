import {
  FETCH_INSTITUTION,
  FETCH_INSTITUTIONS,
} from "../constants/institutions";

const initialState = {
  institution: {},
  institutions: [],
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_INSTITUTION:
      return Object.assign({}, state, {
        institution: action.payload,
      });
    case FETCH_INSTITUTIONS:
      return Object.assign({}, state, {
        institutions: action.payload,
      });
  }
  return state;
}

export default reducer;
