import { SET_USER, LOGIN, LOGOUT } from "../constants/user";

const initialState = {
  user: {},
  loggedIn: false,
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_USER:
      return Object.assign({}, state, {
        user: action.payload,
      });
    case LOGIN:
      return Object.assign({}, state, {
        loggedIn: true,
      });
    case LOGOUT:
      return Object.assign({}, state, {
        loggedIn: false,
      });
  }
  return state;
}

export default reducer;
