export function register(form) {
  let status = "error",
    message = "";
  if (form.name === "") {
    message = "provide a  name";
  } else if (form.phone === "") {
    message = "provide a valid phone number";
  } else if (form.email === "") {
    message = "please provide a valid email address";
  } else if (form.password.length <= 6) {
    message = "password length should be greater than six";
  } else {
    message = "please wait...";
    status = "success";
  }
  return [status, message];
}

export function profile(form) {
  let status = "error",
    message = "";
  if (form.name.length < 6) {
    message = "provide a valid business name";
  } else if (form.description == null || form.description == "") {
    message = "please describe your business";
  } else if (form.state == null || form.state == "") {
    message = "please select your primary state of operation";
  } else if (form.address == "" || form.address == null) {
    message = "please provide an address";
  } else if (form.avatar == null) {
    message = "please upload an image";
  } else {
    message = "please wait...";
    status = "success";
  }
  return [status, message];
}

export function password(form) {
  let status = "error",
    message = "";
  if (form.password.length <= 6) {
    message = "The password length should be greater than six";
  } else if (form.password !== form.rpassword) {
    message = "The password are not the same";
  } else {
    message = "please wait...";
    status = "success";
  }
  return [status, message];
}
