import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Card from "@mui/material/Card";
import Avatar from "@mui/material/Avatar";
import Grid from "@mui/material/Grid";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import CardActions from "@mui/material/CardActions";
import Chip from "@mui/material/Chip";
import LinkIcon from "@mui/icons-material/Link";
import LaunchIcon from "@mui/icons-material/Launch";
import BusinessIcon from "@mui/icons-material/Business";
import EditIcon from "@mui/icons-material/Edit";
import PublishIcon from "@mui/icons-material/Publish";
import UnpublishedIcon from "@mui/icons-material/Unpublished";
import Typography from "@mui/material/Typography";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";

import { fetchPosting, updatePosting } from "../../../actions/postings";

import getSymbolFromCurrency from "currency-symbol-map";
export default function Main(props) {
  const dispatch = useDispatch();
  const institutions = useSelector((state) => state.institutions.institutions);
  const history = useHistory();

  function handlePublish() {
    const body = { ...props.data, published: true };
    dispatch(updatePosting(body));
  }

  function handleUnpublish() {
    const body = { ...props.data, published: false };
    dispatch(updatePosting(body));
  }

  function handleOpen() {
    window.open(props.data.link);
  }

  return (
    <Card
      elevation={2}
      sx={{
        width: "100%",
        height: "220px",
        borderStyle: "solid",
        borderWidth: 1,
        borderColor: "#E5E4E2",
      }}
    >
      <CardHeader
        avatar={
          <Avatar aria-label="recipe">
            {" "}
            <BusinessIcon />
          </Avatar>
        }
        title={
          <Typography noWrap variant="body1" fontFamily="AgrandirBold">
            {props.data.title.length <= 35
              ? props.data.title
              : props.data.title.substr(0, 35) + "..."}
          </Typography>
        }
        subheader={
          <Typography
            color="textSecondary"
            variant="body2"
            fontFamily={"AgrandirRegular"}
          >
            {" "}
            {
              institutions.filter(
                (item) => item.code == props.data.institution
              )[0].name
            }
          </Typography>
        }
      />
      <CardContent>
        <div style={{ marginTop: 0 }}>
          <Typography
            variant="body2"
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitLineClamp: "3",
              WebkitBoxOrient: "vertical",
            }}
            color="textSecondary"
            align="justify"
          >
            {props.data.description}
          </Typography>
        </div>
      </CardContent>
      <CardActions>
        <Grid container direction="row" justifyContent="space-between">
          <Grid item>
            <Chip
              size="small"
              color={props.data.published == true ? "success" : "warning"}
              label={props.data.published == true ? "Published" : "Unpublished"}
            />
          </Grid>
          <Grid item>
            <Grid container direction="row" justifyContent="flex-end">
              <Grid item>
                <IconButton
                  onClick={() => props.display()}
                  size="small"
                  color="primary"
                >
                  <LaunchIcon fontSize="small" />
                </IconButton>
              </Grid>
              <Grid item>
                <IconButton
                  size="small"
                  disabled={props.data.published}
                  onClick={() => props.edit()}
                  color="primary"
                >
                  <EditIcon fontSize="small" />
                </IconButton>
              </Grid>

              <Grid item>
                <IconButton onClick={() => handleOpen()} color="primary">
                  <LinkIcon fontSize="small" />
                </IconButton>
              </Grid>
              <Grid item>
                <IconButton onClick={() => props.confirm()} color="primary">
                  <DeleteIcon color="error" fontSize="small" />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardActions>
    </Card>
  );
}
