import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import Paper from "@mui/material/Paper";
import DashboardIcon from "@mui/icons-material/Dashboard";
import NotificationsIcon from "@mui/icons-material/NotificationsActiveRounded";
import MedicalInformationIcon from "@mui/icons-material/MedicalInformation";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { page } from "../../../actions/index";

export default function Main() {
  const history = useHistory();
  const dispatch = useDispatch();
  const screen = useSelector((state) => state.app.page);
  const [value, setValue] = React.useState(screen || 0);

  function handleChange(event, newValue) {
    dispatch(page(newValue));
    switch (newValue) {
      case 0:
        history.push("/dashboard");
        return;
      case 1:
        history.push("/postings");
        return;
      case 2:
        history.push("/profile");
        return;
      case 3:
        history.push("/notifications");
        return;
    }
  }
  return (
    <Paper
      sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }}
      elevation={3}
    >
      <BottomNavigation showLabels value={screen} onChange={handleChange}>
        <BottomNavigationAction label="Home" icon={<DashboardIcon />} />
        <BottomNavigationAction
          label="Postings"
          icon={<MedicalInformationIcon />}
        />
        <BottomNavigationAction
          disabled
          label="Profile"
          icon={<CalendarMonthIcon />}
        />
        <BottomNavigationAction
          disabled
          label="Notifications"
          icon={<NotificationsIcon />}
        />
      </BottomNavigation>
    </Paper>
  );
}
