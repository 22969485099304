import { defaultHandler } from "../requests/global.js";
import { unexpectedError, wait, notify } from "./index";
import { FETCH_POSTINGS, FETCH_POSTING } from "../constants/postings";

export function getPostings(body) {
  const url = "/poster/postings/poster";
  console.log(body);
  return async (dispatch) => {
    dispatch(wait(true));
    const [res, status] = await defaultHandler(url, body);
    console.log(res);
    if (status == "ok") {
      if (res.response != null) {
        dispatch(fetchPostings(res.response.postings));
      } else {
      }
    } else {
      dispatch(unexpectedError());
    }
    dispatch(wait(false));
  };
}

export function addPosting(body, props) {
  const url = "/poster/postings/add";
  return async (dispatch) => {
    dispatch(wait(true));
    var [res, status] = await defaultHandler(url, body);
    if (status == "ok") {
      if (res.response != null) {
        if (res.response.postings != null) {
          dispatch(fetchPostings(res.response.postings));
          dispatch(
            notify({
              message: "Record created successfully",
              status: "success",
            })
          );
          dispatch(wait(false));
          props.close();
        } else {
          dispatch(
            notify({
              message: res.message,
              status: "error",
            })
          );
        }
      } else {
        dispatch(unexpectedError());
      }
    } else {
      dispatch(unexpectedError());
    }
    dispatch(wait(false));
  };
}

export function updatePosting(body, props) {
  const url = "/poster/postings/update";
  return async (dispatch) => {
    dispatch(wait(true));
    var [res, status] = await defaultHandler(url, body);
    if (status === "ok") {
      if (res.response != null) {
        dispatch(fetchPostings(res.response.postings));
        dispatch(
          notify({
            message: "Record updated successfully",
            status: "success",
          })
        );

        dispatch(wait(false));
        props.close();
      }
    } else {
      dispatch(unexpectedError());
    }

    dispatch(wait(false));
  };
}

export function deletePosting(body, history) {
  const url = "/poster/postings/remove";
  return async (dispatch) => {
    dispatch(wait(true));
    var [res, status] = await defaultHandler(url, body);
    if (res.response != null) {
      dispatch(fetchPostings(res.response.institutions));
      dispatch(
        notify({
          message: "Listing updated successfully",
          status: "success",
        })
      );
    } else {
      dispatch(
        notify({
          message: "An error occurred",
          status: status,
        })
      );
    }
    dispatch(wait(false));
  };
}

export function fetchPostings(payload) {
  return { type: FETCH_POSTINGS, payload };
}

export function fetchPosting(payload) {
  return { type: FETCH_POSTING, payload };
}
